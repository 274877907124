.swiper {
  width: 100%;
  height: 100%;
}

.swiper-button-next, .swiper-button-prev {
  color: white;
  opacity: 0.5;
}

:root {
  --swiper-navigation-size: 12px;
}

.swiper-button-next,
.swiper-button-prev {
  opacity: 1;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.5);
  height: 38px;
  width: 38px;
  top: 10px;
  left: inherit;
}

.swiper-button-next {
  right: 0;
}

.swiper-button-prev {
  right: 145px;
  @media (max-width: 550px) {
    left: 0;
  }
}

.mainSwiper {
  & .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 45px;
    left: inherit;
    width: auto;
    right: 62px;
    @media (max-width: 550px) {
      left: 0;
      width: 100%;
      right: 0;
    }
  }

  & .swiper-pagination-bullet {
    opacity: 0.3;
    background-color: #ffffff !important;
  }

  & .swiper-pagination-bullet-active {
    opacity: 1;
    background-color: #ffffff !important;
  }
}

.templeMobileSwiper {
  padding-bottom: 24px;

  & .swiper-pagination {
    bottom: 0;

    & .swiper-pagination-bullet {
      background-color: #9b51e0;
      opacity: 1;
    }

    & .swiper-pagination-bullet-active {
      background-color: white;
      border: 1.5px solid #9b51e0;
      border-radius: 50%;
      height: 8px;
      opacity: 1;
    }


  }
}

.loginMobileSwiper {
  padding-bottom: 24px;
  border-radius: 12px;

  & .swiper-pagination {
    bottom: 0;

    & .swiper-pagination-bullet {
      background-color: #dfdde4;
      opacity: 1;
    }

    & .swiper-pagination-bullet-active {
      background-color: #ffc737;
      height: 8px;
      opacity: 1;
    }
  }
}