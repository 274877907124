html,
body {
  height: 100%;
}

#app {
  overflow: auto;
  height: 100%;
}

a {
  text-decoration: none;
}

.link {
  text-decoration: none;
  color: #6387e6;

  &:hover {
    color: #455ea1;
  }

  &:active {
    color: #455ea1;
  }

  &:visited {
    color: #6387e6;
  }
}
